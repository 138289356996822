<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="disabledSaveButton"
    :isLoadingSaveButton="isLoadingSaveButton"
    @save="onSaveForm()"
    @cancel="onCancel('EmploymentCalendar')"
  />
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'EmploymentCalendarNew',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'EmploymentCalendar',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'График работы сотрудников',
          route: { name: 'EmploymentCalendar' },
        },
        {
          text: 'Создание графика работы сотрудников',
        },
      ],
      pageHeader: 'Создание графика работы сотрудников',
      initialForm: {
        period: null, // yyyyMM
      },
      form: {},
    };
  },

  computed: {

    filteredHouses() {
      if (!this.form.districtId) return this.houses.data;

      return this.houses.data.filter(
        (item) => this.form.districtId === item.district,
      );
    },

    disabledSaveButton() {
      return !this.form.period;
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'date',
              key: 'period',
              label: 'Период',
              attributes: {
                type: 'month',
                format: 'MM.YYYY',
                valueType: 'YYYYMM',
                timeTitleFormat: 'MM.YYYY',
                required: !this.form.period
              },
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
    }),
    onSaveForm() {
      this.isLoadingSaveButton = true;

      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then(this.handleSaveSuccess)
        .catch(this.handleSaveError)
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },

    handleSaveSuccess(result) {
      if (!result.isSucceed) return;
      this.$router.push({
        name: 'EmploymentCalendarGraphEdit',
        params: { id: result.id },
      });
    },

    handleSaveError(error) {
      console.error('Сохранить не удалось:', error);
    },
  },
};
</script>
